<div class="container py-5 mt-3">  
  <!--Grid row-->
  <div class="row my-4">
      <!--Grid column-->
      <div class="col-md-12 text-center">
        <!-- Nav tabs -->
        <mdb-tabset #menuTabs [buttonClass]="'classic-tabs elegant-color'" [contentClass]="'card'" class="classic-tabs">
            <mdb-tab *ngFor="let carta of cartas" [heading]="carta.title">
                <div class="row text-left">
                    <div class="col-12 century-text">
                        <br>
                        <div>
                            <app-carta-item [carta]="carta"></app-carta-item>
                            <div>
                                <!--<iframe class="gdoc" src="https://docs.google.com/gview?url=https://www.restauranteajonegro.com/assets/carta/AjoNegro_Menu_Gastronomico.pdf&embedded=true" frameborder="0"></iframe>-->
                            </div>
                            <!--<div>
                                <a href="/assets/carta/AjoNegro_Menu_Gastronomico.pdf" target="_blank"><button class="btn btn-default blue-gradient waves-light" mdbWavesEffect>Menú Gastronómico en PDF <i class="fa fa-file-pdf-o ml-1"></i></button></a>
                            </div>-->
                        </div>
                        <br>
                    </div>
                </div>
            </mdb-tab>
            <!--<mdb-tab heading="Nuestra carta">
                <div class="row text-left">
                    <div class="col-12 century-text text-center">
                        <br>
                        <div class="text-center">
                            <div>
                                <iframe class="gdoc" src="https://docs.google.com/gview?url=https://www.restauranteajonegro.com/assets/carta/AjoNegro_Carta.pdf&embedded=true" frameborder="0"></iframe>
                            </div>
                            <div>
                                <a href="/assets/carta/AjoNegro_Carta.pdf" target="_blank"><button class="btn btn-default blue-gradient waves-light" mdbWavesEffect>Carta en PDF <i class="fa fa-file-pdf-o ml-1"></i></button></a>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </mdb-tab>
            <mdb-tab heading="Menú ejecutivo">
                <div class="row text-left">
                    <div class="col-12 century-text">
                        <br>
                        <div class="text-center">
                            <div>
                                <iframe class="gdoc" src="https://docs.google.com/gview?url=https://www.restauranteajonegro.com/assets/carta/AjoNegro_Menu_Ejecutivo.pdf&embedded=true" frameborder="0"></iframe>
                            </div>
                            <div>
                                <a href="/assets/carta/AjoNegro_Menu_Ejecutivo.pdf" target="_blank"><button class="btn btn-default blue-gradient waves-light" mdbWavesEffect>Menú Ejecutivo en PDF <i class="fa fa-file-pdf-o ml-1"></i></button></a>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </mdb-tab>
            <mdb-tab heading="Oferta en barra">
                <div class="row text-left">
                    <div class="col-12 century-text">
                        <br>
                        <div class="text-center">
                            <div>
                                <iframe class="gdoc" src="https://docs.google.com/gview?url=https://www.restauranteajonegro.com/assets/carta/AjoNegro_Barra.pdf&embedded=true" frameborder="0"></iframe>
                            </div>
                            <div>
                                <a href="/assets/carta/AjoNegro_Barra.pdf" target="_blank"><button class="btn btn-default blue-gradient waves-light" mdbWavesEffect>Oferta en barra en PDF <i class="fa fa-file-pdf-o ml-1"></i></button></a>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </mdb-tab>
            <mdb-tab heading="Nuestros vinos">
                <div class="row text-left">
                    <div class="col-12 century-text">
                        <br>
                        <div class="text-center">
                            <div>
                                <iframe  class="gdoc" src="https://docs.google.com/gview?url=https://www.restauranteajonegro.com/assets/carta/AjoNegro_Vinos.pdf&embedded=true" frameborder="0"></iframe>
                            </div>
                            <div>
                                <a href="/assets/carta/AjoNegro_Vinos.pdf" target="_blank"><button class="btn btn-default blue-gradient waves-light" mdbWavesEffect>Carta en PDF <i class="fa fa-file-pdf-o ml-1"></i></button></a>
                            </div>
                        </div>
                        <br>
                    </div> 
                </div>
            </mdb-tab>-->
          </mdb-tabset>
      </div>
      <!--Grid column-->    
  </div>
  <!--Grid row-->
</div>