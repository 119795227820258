import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { AppReserva, ReservaStatus, ReservaChangeType } from '../../classes/appreserva';

@Component({
  selector: 'app-listado-reservas',
  templateUrl: './listado-reservas.component.html',
  styleUrls: ['./listado-reservas.component.scss']
})
export class ListadoReservasComponent implements OnInit {

    reservas: AppReserva[];
    estadoReserva: any = ReservaStatus;
    range = { start: new Date(), end: null };

    constructor(private apiService: ApiService) {
        var dateEnd = new Date();
        dateEnd.setDate(dateEnd.getDate() + 13);
        this.range.end = dateEnd;
    }

    ngOnInit() {
        this.apiService.GetReservas(this.range.start, this.range.end).then((reservas) => {
            console.log(reservas);
            this.reservas = reservas;
        });
    }

    onRangeChange(value: any) {
        this.range.start = value.start;
        this.range.end = value.end;
        this.apiService.GetReservas(this.range.start, this.range.end).then((reservas) => {
            console.log(reservas);
            this.reservas = reservas;
        });
    }

    traduceCambio(cambio: ReservaChangeType) {
        if (cambio == ReservaChangeType.Status) {
            return "Estado";
        } else if (cambio == ReservaChangeType.Date) {
            return "Fecha";
        } else if (cambio == ReservaChangeType.Customers) {
            return "Comensales";
        } else if (cambio == ReservaChangeType.Comment) {
            return "Comentario";
        } else {
            return "Desconocido " + cambio;
        }
    }

    bitwise(one, two) {
        return one & two;
    }

    postComentarioReserva(reservaId: string, mensaje: string) {
        this.apiService.PostComentarioReserva(reservaId, mensaje);
    }

    postEstadoReserva(reservaId: string, estado: ReservaStatus) {
        this.apiService.PostEstadoReserva(reservaId, estado);
    }

    //editarReserva(reserva: AppReserva) {
    //    console.log(reserva);
    //    reserva._edit = !reserva._edit;
    //}

}
