<div class="container text-center py-5 mt-3">

    <div class="md-form">
        <mdb-select-2 placeholder="Menú a editar" label="Menús" (ngModelChange)="getSelectedMenu($event)" [ngModel]="selection">
            <mdb-select-option *ngFor="let menu of menus" [value]="menu">{{ menu.title }}</mdb-select-option>
        </mdb-select-2>

        <button mdbBtn (click)="createMenu()" type="button" color="primary" outline="true" mdbWavesEffect>Crear</button>
        <button mdbBtn *ngIf="selectedMenu" (click)="moveDownMenu()" type="button" color="primary" outline="true" mdbWavesEffect><mdb-icon fas icon="arrow-alt-circle-down"></mdb-icon> Bajar</button>
        <button mdbBtn *ngIf="selectedMenu" (click)="moveUpMenu()" type="button" color="primary" outline="true" mdbWavesEffect><mdb-icon fas icon="arrow-alt-circle-up"></mdb-icon> Subir</button>

    </div>

    <div *ngIf="selectedMenu">

        <div class="md-form">
            <div class="input-group">
                <input mdbInput type="text" [(ngModel)]="selectedMenu.title" name="text" id="menuTitle" class="form-control">
                <label for="menuTitle" class="">Título de la carta</label>
                <div class="input-group-append">
                    <!--<button class="btn btn-outline-secondary" type="button" (click)="delete()">Borrar</button>-->
                    <div class="dropdown" mdbDropdown>
                        <button mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light" type="button"
                                mdbWavesEffect>
                            Acciones
                        </button>

                        <div class="dropdown-menu dropdown-primary">
                            <a class="dropdown-item" (click)="createSection()" href="javascript:void(0)">
                                <mdb-icon far icon="plus-square"></mdb-icon>
                                Añadir sección
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-admin-carta-section *ngFor="let section of selectedMenu.sections" [section]="section" (onDelete)="deleteSection($event)" (onClone)="cloneSection($event)" (onMoveUp)="moveUpSection($event)" (onMoveDown)="moveDownSection($event)"></app-admin-carta-section>

        <!--<button mdbBtn (click)="createSection()" type="button" color="primary" outline="true" mdbWavesEffect>Añadir sección</button>-->

        <div class="md-form">
            <input type="number" [(ngModel)]="selectedMenu.price" class="form-control" id="menuPrice" mdbInput>
            <label for="menuPrice">Precio fijo</label>
        </div>

        <div class="md-form">
            <textarea type="text" [(ngModel)]="selectedMenu.information" id="menuInformation" class="md-textarea md-textarea-auto form-control" mdbInput></textarea>
            <label for="menuInformation">Información relativa al menú</label>
        </div>

        <button *ngIf="loaded" (click)="postMenu()" mdbBtn type="button" color="primary" mdbWavesEffect>
            <mdb-icon far icon="save" class="mr-1"></mdb-icon>Guardar
        </button>

    </div>

</div>