<div *ngFor="let section of carta.sections">
    <h4 class="mt-1 font-weight-bold">
        {{section.title | uppercase }}
    </h4>
    <div class="ml-2" *ngFor="let item of section.items">
        <div class="mt-2 clearfix" *ngIf="item.type == 1; else bebida">
            <p class="float-left">
                <span class="font-weight-bold font-italic">{{item.title}}</span>&nbsp;<span>{{item.description}}</span>
            </p>
            <p *ngIf="item.price" class="float-right font-weight-bold">{{item.price | number:'1.0-2'}}€</p>
        </div>
        <ng-template #bebida>
            <div class="mt-2 clearfix">
                <div class="row no-gutters">
                    <div class="col-6 col-md-8">
                        <span>{{item.title}}</span>
                    </div>
                    <div class="col-3 col-md-2">
                        <span>{{item.priceCup ? (item.priceCup | number:'1.0-2') + ' €' : '...'}}</span>
                    </div>
                    <div class="col-3 col-md-2">
                        <span>{{item.priceBottle | number:'1.0-2'}} €</span>
                    </div>
                </div>
                <small>
                    {{item.description}}
                </small>
            </div>
        </ng-template>
        <div *ngFor="let variant of item.variants" class="clearfix">
            <p class="ml-4 float-left">- {{variant.title}}&nbsp;{{variant.description}}</p>
            <p *ngIf="variant.price" class="float-right font-weight-bold">{{variant.price | number:'1.0-2'}}€</p>
        </div>
    </div>
</div>
<div *ngIf="carta.price" class="m-3 text-center">
    <span class="font-weight-bold">Precio: {{carta.price | number:'1.0-2'}} €</span> (por persona)
</div>
<div *ngIf="carta.information" class="info-box m-3">
    <div class="m-3" [innerHTML]="carta.information">
    </div>
</div>