import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { TabsetComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-carta',
  templateUrl: './carta.component.html',
  styleUrls: ['./carta.component.scss']
})
export class CartaComponent implements OnInit {
    @ViewChild('menuTabs') menuTabs: TabsetComponent

    cartas: any[];

    constructor(private apiService: ApiService) {

    }

    ngOnInit() {
        this.apiService.GetMenus(true).then((cartas) => {
            this.cartas = cartas;
            console.log(cartas);

            setTimeout(() => {
                this.menuTabs.setActiveTab(1);
            }, 0);
        });
    }

}
