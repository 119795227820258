import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-carta-item',
  templateUrl: './admin-carta-item.component.html',
  styleUrls: ['./admin-carta-item.component.scss']
})
export class AdminCartaItemComponent implements OnInit {
    @Input()
    item: any;
    @Output()
    onDelete = new EventEmitter<any>();
    @Output()
    onClone = new EventEmitter<any>();
    @Output()
    onMoveUp = new EventEmitter<any>();
    @Output()
    onMoveDown = new EventEmitter<any>();

    itemTypes = [
        { id: 1, title: 'Plato' },
        { id: 2, title: 'Bebida' }
    ];

    constructor() { }

    ngOnInit(): void {
        console.log(this.itemTypes);
    }

    createVariant() {
        var newVariant = {
            title: "Nueva variante de plato"
        }; 

        this.item.variants.push(newVariant);
    }

    clone() {

        var cloned = JSON.parse(JSON.stringify(this.item));

        delete cloned['id'];

        cloned.variants.forEach((variant) => {
            variant['id'];
        });

        this.onClone.emit(cloned);
    }

    delete() {
        this.onDelete.emit(this.item);
    }

    moveUp() {
        this.onMoveUp.emit(this.item);
    }

    moveDown() {
        this.onMoveDown.emit(this.item);
    }

    deleteVariant(variant) {
        var idx = this.item.variants.findIndex(s => s == variant);
        this.item.variants.splice(idx, 1);
    }

    cloneVariant(item) {
        this.item.variants.push(item);
    }

    moveUpVariant(item) {
        var idx = this.item.variants.findIndex(s => s == item);

        this.item.variants.splice(idx, 1);
        this.item.variants.splice(idx - 1, 0, item);

        console.log(this.item.variants);
    }

    moveDownVariant(item) {
        var idx = this.item.variants.findIndex(s => s == item);

        this.item.variants.splice(idx, 1);
        this.item.variants.splice(idx + 1, 0, item);

        console.log(this.item.variants);
    }

    getSelectedType(type) {
        console.log(type);
    }

}
