import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class AdminNoticiasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
