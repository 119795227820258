import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthService } from './auth.service';

@Directive({
  selector: '[withClaim]'
})
export class WithClaimDirective implements OnInit{

    private claim: string;
    private value: string;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) { }

    ngOnInit() {

        this.authService.isAuthorizedSubject.subscribe(x => {
            this.updateView();
        });

        this.authService.userDataSubject.subscribe(y => {
            this.updateView();
        });
    }

  @Input()
  set withClaim(claimname) {
    this.claim = claimname;
    this.updateView();
  }

  @Input()
  set withClaimValue(claimvalue) {
    this.value = claimvalue;
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
      return this.authService.hasClaim(this.claim, this.value);
  }
}
