import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-carta-item-variant',
  templateUrl: './admin-carta-item-variant.component.html',
  styleUrls: ['./admin-carta-item-variant.component.scss']
})
export class AdminCartaItemVariantComponent implements OnInit {
    @Input()
    variant: any;
    @Output()
    onDelete = new EventEmitter<any>();
    @Output()
    onClone = new EventEmitter<any>();
    @Output()
    onMoveUp = new EventEmitter<any>();
    @Output()
    onMoveDown = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }

    delete() {
        this.onDelete.emit(this.variant);
    }

    clone() {
        var cloned = JSON.parse(JSON.stringify(this.variant));

        delete cloned['id'];

        this.onClone.emit(cloned);
    }

    moveUp() {
        this.onMoveUp.emit(this.variant);
    }

    moveDown() {
        this.onMoveDown.emit(this.variant);
    }
}
