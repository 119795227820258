import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

    //igImages: any[] = []; 

    constructor(private api: ApiService) {
        //api.InstagramGetLatestUserMedia().then((res: any[]) => {
        //    this.igImages.length = 0;
        //    this.igImages.push(...res);
        //    console.log(this.igImages);
        //});
    }

    ngOnInit() {
    
    }

}
