import { Injectable, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PackageJson } from './package';

@Injectable({
  providedIn: 'root'
})

export class AuthService implements OnDestroy {

  // Observable sources
  isAuthorizedSubject = new BehaviorSubject<boolean>(false);
  userDataSubject = new BehaviorSubject<any>(null);
  userProfileSubject = new BehaviorSubject<any>(null);

  private isAuthorizedSubscription: Subscription;
  private userDataSubscription: Subscription;

  constructor(private oidcSecurityService: OidcSecurityService, private packageJson: PackageJson) {
    if (this.oidcSecurityService.moduleSetup) {
      this.doCallbackLogicIfRequired();
      this.Initialize();
    } else {
      this.oidcSecurityService.onModuleSetup.subscribe(() => {
        this.doCallbackLogicIfRequired();
        this.Initialize();
      });
    }
  }

  Initialize() {
    if (!this.isAuthorizedSubscription) {
      this.isAuthorizedSubscription = this.oidcSecurityService.getIsAuthorized().subscribe(
        isAuth => {
          this.isAuthorizedSubject.next(isAuth);
          if (!isAuth) {
            this.userDataSubject.next(null);
            this.userProfileSubject.next(null);
          }
        });
    }

    if (!this.userDataSubscription) {
      this.userDataSubscription = this.oidcSecurityService.getUserData().subscribe(
        userData => {
          this.userDataSubject.next(userData);
          if (userData) {
            const adLinked = userData.ad_linked == 'true';
            this.userProfileSubject.next({
              Name: adLinked ? userData.ad_name : userData.preferred_username,
              AdLinked: adLinked,
              Picture: adLinked ? (this.packageJson.Config['identity_host'] + userData.ad_picture) : ''
            });
          }
        });
    }
  }

  hasClaim(claim: string, value?: string): boolean {
    if (this.isAuthorizedSubject.value && this.userDataSubject.value) {
      if (value) {
        if (this.userDataSubject.value.hasOwnProperty(claim)) {
          if (typeof this.userDataSubject.value[claim] === "string") {
            return this.userDataSubject.value[claim] === value;
          } else if (this.userDataSubject.value[claim] instanceof Array) {
            var str = this.userDataSubject.value[claim] as string[];
            return str.findIndex(x => {
              return x === value
            }) > -1;
          }
        }
        return false;
      } else {
        return this.userDataSubject.value.hasOwnProperty(claim);
      }
    }
  }

  getIdToken(): string {
    return this.oidcSecurityService.getToken();
  }

  getToken(): string {
    return this.oidcSecurityService.getToken();
  }

  login(additionalParameters?: {
        [key: string]: string | number | boolean;
  }) {

    if (additionalParameters) {
        this.oidcSecurityService.setCustomRequestParameters(additionalParameters);
    }
    
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.userProfileSubject.next(null);
    this.oidcSecurityService.logoff();
  }

  ngOnDestroy(): void {
    if (this.isAuthorizedSubscription) {
      this.isAuthorizedSubscription.unsubscribe();
    }
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  private doCallbackLogicIfRequired() {
    this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
  }

}
