<div class="pl-2 pr-2">

    <div class="md-form">
        <div class="input-group">
            <input mdbInput type="text" [(ngModel)]="variant.title" name="text" class="form-control">
            <label class="">Título de la variante del plato</label>
            <div class="input-group-append">
                <!--<button class="btn btn-outline-secondary" type="button" (click)="delete()">Borrar</button>-->
                <div class="dropdown" mdbDropdown>
                    <button mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light" type="button"
                            mdbWavesEffect>
                        Acciones
                    </button>

                    <div class="dropdown-menu dropdown-primary">
                        <a class="dropdown-item" (click)="delete()" href="javascript:void(0)">
                            <mdb-icon far icon="trash-alt"></mdb-icon>
                            Borrar
                        </a>
                        <a class="dropdown-item" (click)="clone()" href="javascript:void(0)">
                            <mdb-icon far icon="copy"></mdb-icon>
                            Clonar
                        </a>
                        <a class="dropdown-item" (click)="moveUp()" href="javascript:void(0)">
                            <mdb-icon fas icon="arrow-up"></mdb-icon>
                            Subir
                        </a>
                        <a class="dropdown-item" (click)="moveDown()" href="javascript:void(0)">
                            <mdb-icon fas icon="arrow-down"></mdb-icon>
                            Bajar
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-group">
            <input mdbInput type="text" [(ngModel)]="variant.description" name="text" class="form-control">
            <label class="">Descripción de la variante del plato</label>
        </div>
        <div class="row">
            <div class="col-12">
                <kendo-floatinglabel text="Precio">
                    <kendo-numerictextbox [(ngModel)]="variant.price" type="number"></kendo-numerictextbox>
                </kendo-floatinglabel>
            </div>
        </div>
    </div>

</div>