<mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar" [containerInside]="false">
  <logo>
      <a class="logo navbar-brand" [routerLink]="['/']"><strong>Ajonegro</strong></a>
  </logo>
  <links>
      <ul class="navbar-nav mr-auto">
          <li class="nav-item waves-light" [routerLinkActive]="['active']" mdbRippleRadius>
              <a class="nav-link" [routerLink]="['/index']">Inicio </a>
          </li>
          <li class="nav-item waves-light" [routerLinkActive]="['active']" mdbRippleRadius>
              <a class="nav-link" [routerLink]="['/galeria']">Galería</a>
          </li>
          <li class="nav-item waves-light" [routerLinkActive]="['active']" mdbRippleRadius>
              <a class="nav-link" [routerLink]="['/carta']">Carta</a>
          </li>
          <li class="nav-item waves-light" [routerLinkActive]="['active']" mdbRippleRadius>
              <a class="nav-link" [routerLink]="['/reservas']">Reservas y grupos</a>
          </li>
          <li class="nav-item waves-light" [routerLinkActive]="['active']" mdbRippleRadius>
              <a class="nav-link" [routerLink]="['/mapa']">Localízanos</a>
          </li>
      </ul>

      <ul class="navbar-nav ml-auto">
          <li *withClaim="claimName; value 'admin'" class="nav-item dropdown" dropdown>
              <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                  Administración<span class="caret"></span>
              </a>
              <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                  <!--<a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['admin', 'noticias']">Noticias</a>
                  <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['admin', 'galeria']">Galería</a>-->
                  <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['admin', 'carta']">Carta</a>
                  <!--<a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['admin', 'horario']">Horario</a>-->
                  <!--<div class="divider dropdown-divider"></div>
                  <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['admin', 'reservas']">Reservas</a>-->
              </div>
          </li>

          <li *ngIf="(authService.isAuthorizedSubject | async);then authenticated else anonymous"></li>
          <ng-template #anonymous>
              <li *ngIf="router.url === '/admin'" class="nav-item">
                  <a class="nav-link waves-light" mdbWavesEffect (click)="Login()"><mdb-icon fas icon="fa fa-user"></mdb-icon> Login</a>
                  <!--<a class="nav-link waves-light" mdbWavesEffect (click)="Login()">
        <span class="clearfix d-none d-sm-inline-block"><i class="fa fa-user"></i> Login</span>
    </a>-->
              </li>
          </ng-template>
          <ng-template #authenticated>
              <li class="nav-item">
                  <!--<a class="nav-link waves-light" mdbWavesEffect (click)="Logout()">
        <span class="d-none d-sm-inline-block"><i class="fa fa-user"></i> {{(authService.userProfileSubject | async) ? (authService.userProfileSubject | async).Name : (authService.userDataSubject | async).preferred_username}}</span>
    </a>-->
                  <a class="nav-link waves-light" mdbWavesEffect (click)="Logout()"><mdb-icon fas icon="fa fa-user"></mdb-icon> {{(authService.userProfileSubject | async) ? (authService.userProfileSubject | async).Name : (authService.userDataSubject | async).preferred_username}}</a>
              </li>
          </ng-template>
      </ul>
  </links>
</mdb-navbar>
