export enum ReservaStatus { Created = 1, ConfirmedByGuest = 2, ConfirmedByOwner = 4, AwaitingOwnerAction = 8, AwaitingUserAction = 16, CancelledByOwner = 32, CancelledByGuest = 64 }
export enum ReservaChangeType { Status = 0, Date = 1, Customers = 2, Comment = 4 }; 

export class AppReserva {
    id: string;
    // Fecha 
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    // Datos
    name: string;
    phone: string;
    email: string;
    customers: number;
    newComments: string;
    // Estado
    status: ReservaStatus;

    logs: AppReservaLog[];
    comments: AppReservaComentario[];

    _edit: boolean = false;
    _newMessage: string = "";

    constructor() {

    }
}

export class AppReservaComentario {
    id: number;
    userId: number;
    isOwner: boolean;
    username: string;
    text: string;
    creationDate: Date;
}

export class AppReservaLog {
    id: number;
    estado: ReservaStatus;
    cambio: ReservaChangeType;

    userId: string;

    prevYear: number;
    prevMonth: number;
    prevDay: number;
    prevHour: number;
    prevMinute: number;
    nextYear: number;
    nextMonth: number;
    nextDay: number;
    nextHour: number;
    nextMinute: number;

    prevCustomers: number;
    newCustomers: number;

    creationDate: Date;

}