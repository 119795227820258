import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-admin-carta-section',
  templateUrl: './admin-carta-section.component.html',
  styleUrls: ['./admin-carta-section.component.scss']
})
export class AdminCartaSectionComponent implements OnInit {
    @Input()
    section: any;
    @Output()
    onDelete = new EventEmitter<any>();
    @Output()
    onClone = new EventEmitter<any>();
    @Output()
    onMoveUp = new EventEmitter<any>();
    @Output()
    onMoveDown = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }

    createItem() {
        var newItem = {
            title: "Nuevo item",
            type: 1,
            variants: []
        };

        this.section.items.push(newItem);
    }

    delete() {
        this.onDelete.emit(this.section);
    }

    clone() {
        var cloned = JSON.parse(JSON.stringify(this.section));

        delete cloned['id'];

        cloned.items.forEach((item) => {
            delete item['id'];
            item.variants.forEach((variant) => {
                delete variant['id'];
            });
        });
        
        this.onClone.emit(cloned);
    }

    moveUp() {
        this.onMoveUp.emit(this.section);
    }

    moveDown() {
        this.onMoveDown.emit(this.section);
    }

    deleteItem(item) {
        var idx = this.section.items.findIndex(s => s == item);
        this.section.items.splice(idx, 1);
    }

    cloneItem(item) {
        this.section.items.push(item);
    }

    moveUpItem(item) {
        var idx = this.section.items.findIndex(s => s == item);

        this.section.items.splice(idx, 1);
        this.section.items.splice(idx - 1, 0, item);

        console.log(this.section.items);
    }

    moveDownItem(item) {
        var idx = this.section.items.findIndex(s => s == item);

        this.section.items.splice(idx, 1);
        this.section.items.splice(idx + 1, 0, item);

        console.log(this.section.items);
    }
}
