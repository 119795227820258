import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ToastModule } from 'ng-uikit-pro-standard';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, isDevMode, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { NgxPaginationModule } from 'ngx-pagination'
import { MomentModule } from 'ngx-moment';

import { IntlModule } from '@progress/kendo-angular-intl';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { CalendarModule, DateRangeModule, MultiViewCalendarModule } from '@progress/kendo-angular-dateinputs';
import '@progress/kendo-angular-intl/locales/es/all';

import * as moment from 'moment';
import 'moment/min/locales';
//import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AppComponent } from './app.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { IndexComponent } from './index/index.component';
import { ReservasComponent } from './reservas/reservas.component';
import { CartaComponent } from './carta/carta.component';

import { FooterComponent } from './footer/footer.component';
import { MapaComponent } from './mapa/mapa.component';
import { PlatoComponent } from './carta/plato/plato.component';
import { BotellaComponent } from './carta/botella/botella.component';
import { ComidaComponent } from './carta/comida/comida.component';

import { WithClaimDirective } from './with-claim.directive';
import { AdminComponent } from './admin/admin.component';
import { AdminNoticiasComponent } from './admin/noticias/noticias.component';
import { AdminHorarioComponent } from './admin/horario/horario.component';
import { AdminGaleriaComponent } from './admin/galeria/galeria.component';
import { AdminReservasComponent } from './admin/reservas/reservas.component';
import { AdminCartaComponent } from './admin/carta/carta.component';

import { LOCAL_STORAGE } from 'ngx-webstorage-service';
import { OIDC_SERVICE_STORAGE, CustomStorage } from './storage';

import {
    AuthModule, 
    OidcSecurityService,
    OidcConfigService,
    OpenIdConfiguration,
    ConfigResult
} from 'angular-auth-oidc-client';

import { PackageJson, PackageJsonConfig, PACKAGE_JSON_CONFIG } from './package';
import { ConfigService } from './config.service';
 
import { AuthInterceptor } from './http-interceptor-auth';
import { FormsModule } from '@angular/forms';
import { EstadoReservaComponent } from './reservas/estado-reserva/estado-reserva.component';
import { CrearReservaComponent } from './reservas/crear-reserva/crear-reserva.component';
import { GestionReservaComponent } from './reservas/gestion-reserva/gestion-reserva.component';
import { ListadoReservasComponent } from './reservas/listado-reservas/listado-reservas.component';
import { ConfirmarReservaComponent } from './reservas/confirmar-reserva/confirmar-reserva.component';
import { IgGalleryComponent } from './ig-gallery/ig-gallery.component';
import { AdminCartaSectionComponent } from './admin/carta/admin-carta-section/admin-carta-section.component';
import { AdminCartaItemComponent } from './admin/carta/admin-carta-item/admin-carta-item.component';
import { AdminCartaItemVariantComponent } from './admin/carta/admin-carta-item-variant/admin-carta-item-variant.component';
import { CartaItemComponent } from './carta/carta-item/carta-item.component';

registerLocaleData(localeEs);
moment.locale('es');

const appRoutes: Routes = [
    { path: '', component: IndexComponent, pathMatch: 'full' },
    { path: 'admin', component: IndexComponent },
    { path: 'admin/noticias', component: AdminNoticiasComponent },
    { path: 'admin/galeria', component: AdminGaleriaComponent },
    { path: 'admin/horario', component: AdminHorarioComponent },
    { path: 'admin/reservas', component: ListadoReservasComponent },
    { path: 'admin/carta', component: AdminCartaComponent },
    { path: 'galeria',  component: GaleriaComponent },
    { path: 'carta',    component: CartaComponent },
    { path: 'reservas', component: ReservasComponent },
    { path: 'reservas/solicitar', component: CrearReservaComponent },
    { path: 'reservas/confirmar', component: ConfirmarReservaComponent },
    { path: 'mapa', component: MapaComponent },
    { path: '**', redirectTo: '' }
];

const packageJsonConfig: PackageJsonConfig = {
    packageJsonConfigRoot: "idconfig"
}

export function loadConfig(oidcConfigService: OidcConfigService, packageJson: PackageJson) {
    return () => oidcConfigService.load_using_stsServer(packageJson.Config["identity_host"]);
}

@NgModule({
  declarations: [
    AppComponent,
    WithClaimDirective,
    NavMenuComponent,
    GaleriaComponent,
    IndexComponent,
    ReservasComponent,
    CartaComponent,
    FooterComponent,
    MapaComponent,
    PlatoComponent,
    BotellaComponent,
    ComidaComponent,
    AdminComponent,
    AdminNoticiasComponent,
    AdminHorarioComponent,
    AdminCartaComponent,
    AdminGaleriaComponent,
    AdminReservasComponent,
    EstadoReservaComponent,
    CrearReservaComponent,
    GestionReservaComponent,
    ListadoReservasComponent,
    ConfirmarReservaComponent,
    IgGalleryComponent,
    AdminCartaSectionComponent,
    AdminCartaItemComponent,
    AdminCartaItemVariantComponent,
    CartaItemComponent
  ],
  imports: [
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot({ opacity: 1, positionClass: 'md-toast-top-center' }),
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
    BrowserModule,
    BrowserAnimationsModule,
    IntlModule,
    FormsModule,
    HttpClientModule,
    AuthModule.forRoot({ storage: CustomStorage }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDCgItzxvqR298CwgQpDgJIWcPv-jhzGg4'
    }),
    NgxPaginationModule,
    MomentModule,
    AgmSnazzyInfoWindowModule,
    CalendarModule,
    DateRangeModule,
    MultiViewCalendarModule,
    InputsModule,
    LabelModule
  ],
    providers: [
        MDBSpinningPreloader,
        {
            provide: OIDC_SERVICE_STORAGE,
            useExisting: LOCAL_STORAGE
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        CustomStorage,
        OidcConfigService,
        OidcSecurityService,
        { provide: PACKAGE_JSON_CONFIG, useValue: packageJsonConfig },
        { provide: LOCALE_ID, useValue: 'es-ES' },
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [OidcConfigService, PackageJson],
            multi: true
        }
    ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { 
    constructor(
        private oidcSecurityService: OidcSecurityService,
        private oidcConfigService: OidcConfigService,
        private configService: ConfigService,
        private packageJson: PackageJson
    ){
        this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {

            const url_app = configService.host + (configService.port ? (':' + configService.port) : '');

            const openIDConfig: OpenIdConfiguration = {
                stsServer: this.packageJson.Config["identity_host"],
                redirect_url: url_app + '/signin-oidc',
                client_id: this.packageJson.Config["client_id"],
                scope: this.packageJson.Config["scope"],
                response_type: 'code',
                post_logout_redirect_uri: url_app + '/signout-callback-oidc',
                post_login_route: '/',
                forbidden_route: '/Forbidden',
                unauthorized_route: '/Unauthorized',
                start_checksession: true,
                silent_renew: true,
                silent_renew_url: url_app + '/silent-renew.html',
                log_console_debug_active: isDevMode(),
                disable_iat_offset_validation: true
            };

            this.oidcSecurityService.setupModule(openIDConfig, configResult.authWellknownEndpoints);
        });
    }
}
