import { Injectable, InjectionToken, Inject } from "@angular/core";
import { OidcSecurityStorage } from "angular-auth-oidc-client";
import { StorageService } from "ngx-webstorage-service";

export const OIDC_SERVICE_STORAGE = new InjectionToken<StorageService>('OIDC_SERVICE_STORAGE');

@Injectable()
export class CustomStorage implements OidcSecurityStorage{

  constructor(@Inject(OIDC_SERVICE_STORAGE) private storage: StorageService) {
  }

  public read(key: string): any {
    return this.storage.get(key);
  }

  public write(key: string, value: any): void {
    this.storage.set(key, value);
  }

}
