import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { PackageJson } from '../package';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
    claimName: string;
    constructor(public authService: AuthService, public router: Router, private packageJson: PackageJson) {
        this.claimName = this.packageJson.Config["client_id"]; 
    }

    ngOnInit() {
    }

    public Login() {
        this.authService.login();
    }

    public Logout() {
        this.authService.logout();
    }

}
