<div class="container py-5 mt-3">
    <div class="row my-4">
        <div class="col-md-12 text-center">
            <div class="card">
                <div class="card-header elegant-color lighten-1 white-text">
                    Reservas y grupos
                </div>
                <div class="card-body">
                    <iframe src="https://module.lafourchette.com/es_ES/module/722169-b0173 " style="width: 100%; min-height:700px; border:none; overflow:scroll;"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>