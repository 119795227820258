import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class AdminGaleriaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
