<div class="container py-5 mt-3">
    <h1>Solicitar reserva</h1>
    <span>Para crear tu reserva, debes indicarnos el día y la hora a la que vas a acudir, junto al número de comensales. Confirmaremos tu reserva vía telefónica y recibirás un email en el que tendrás a tu disposición el estado de la reserva en todo momento.</span>
    <div class="row">
        <div class="col-lg-6">
            <h2>
                Datos de contacto
            </h2>
            <div class="md-form">
                <i class="fas fa-user prefix"></i>
                <input mdbInput type="text" id="name" class="form-control" [(ngModel)]="reserva.name">
                <label for="name">Nombre</label>
            </div>
            <div class="md-form">
                <i class="fas fa-mobile-alt prefix"></i>
                <input mdbInput type="tel" id="phone" class="form-control" [(ngModel)]="reserva.phone">
                <label for="phone">Teléfono</label>
            </div>
            <div class="md-form">
                <i class="fas fa-envelope prefix"></i>
                <input mdbInput type="email" id="email" class="form-control" [(ngModel)]="reserva.email">
                <label for="email">Email</label>
            </div>
            <h3>Número de comensales</h3>
            <div class="md-form">
                <input type="number" class="form-control" id="numberInputEx" [(ngModel)]="reserva.customers" mdbInput>
                <label for="numberInputEx">Comensales</label>
            </div>
        </div>
        <div class="col-lg-6">
            <h2>
                Fecha de la solicitud de reserva
            </h2>
            <div>
                <!--<mdb-date-picker (dateChanged)="setFecha($event)" [options]="dtpOptions" [placeholder]="'Seleccionar fecha'" [(ngModel)]="fechaReserva" required></mdb-date-picker>-->
                <kendo-calendar [weekNumber]="true" [(ngModel)]="fechaReserva" (valueChange)="setFecha($event)">
                    <ng-template kendoCalendarMonthCellTemplate let-date>
                        <span [ngClass]="isMeeting(date)">{{date.getDate()}}</span>
                    </ng-template>
                    <ng-template kendoCalendarYearCellTemplate let-context="cellContext">
                        <span [ngClass]="isYearMeeting(context.value)">{{context.formattedValue}}</span>
                    </ng-template>
                    <ng-template kendoCalendarDecadeCellTemplate let-context="cellContext">
                        <span [ngClass]="isDecadeMeeting(context.value)">{{context.formattedValue}}</span>
                    </ng-template>
                    <ng-template kendoCalendarCenturyCellTemplate let-context="cellContext">
                        <span [ngClass]="isCenturyMeeting(context.value)">{{context.formattedValue}}</span>
                    </ng-template>
                    <ng-template kendoCalendarWeekNumberCellTemplate let-context="cellContext">
                        <span class="weeknumber-cell">{{context.formattedValue}}</span>
                    </ng-template>
                    <ng-template kendoCalendarHeaderTitleTemplate let-title>
                        {{title}}
                    </ng-template>
                    <ng-template kendoCalendarNavigationItemTemplate let-title let-date="date" let-view="activeView">
                        {{title}}
                    </ng-template>
                </kendo-calendar>
            </div>
        </div>
        <div class="col-lg-12">
            <h3>Hora de la reserva</h3>
            <div class="btn-group" mdbDropdown>
                <button mdbDropdownToggle mdbBtn color="primary" size="md" class="dropdown-toggle waves-light" type="button" mdbWavesEffect>
                    <span *ngIf="horarioSeleccionado;else sin_horario">{{horarioSeleccionado.Hour}}:{{horarioSeleccionado.Minute | number:'2.0-0'}}</span>
                    <ng-template #sin_horario><span>Seleccionar horario</span></ng-template>
                </button>
                <div class="dropdown-menu">
                    <a *ngFor="let horario of horarios" class="dropdown-item" (click)="setHorario(horario)" [ngClass]="{'active' : horarioSeleccionado == horario}" [routerLink]="">{{horario.Hour}}:{{horario.Minute | number:'2.0-0'}}</a>
                </div>
            </div>
            <div class="md-form">
                <i class="fas fa-pencil-alt prefix"></i>
                <textarea type="text" id="form8" class="md-textarea form-control" [(ngModel)]="reserva.newComments" mdbInput></textarea>
                <label for="form8">Alergias / comentarios</label>
            </div>
        </div>
    </div>
    <button (click)="solicitarReserva()" type="button" mdbBtn class="btn-git waves-light" mdbWavesEffect><i class="far fa-smile left"></i>Solicitar reserva</button>
</div>