<div class="container py-5 mt-3">
    <div class="row my-4">
        <div class="col-md-12 text-center">
          <agm-map [latitude]="latMap" [longitude]="lngMap" [zoom]="zoom">
            <agm-marker [latitude]="lat" [longitude]="lng">
              <agm-snazzy-info-window isOpen="true" [maxWidth]="800" [maxHeight]="1200" [closeWhenOthersOpen]="true" [disableAutoPan]="true">
                <ng-template>
                    <div class="container" style="min-width:300px">
                        <h5>Restaurante Ajo Negro</h5>
                        <br>
                            <a href="https://www.google.com/maps/search/?api=1&query=Restaurante+Ajo+Negro,+bajo+9,+Calle+Hermanos+Moroy,+1,+26001+Logroño,+La+Rioja" target="_blank" mdbBtn floating="true" size="lg" gradient="blue" mdbWavesEffect>
                                <mdb-icon fas icon="map-marked-alt"></mdb-icon>
                            </a>
                            <br>
                            <br>
                            <h6>Horario</h6>
                            <span [ngClass]="{ 'font-weight-bold': dia === 1 }">Lunes - Cerrado</span>
                            <br>
                            <span [ngClass]="{ 'font-weight-bold': dia === 2 }">Martes - Cerrado</span>
                            <br>
                            <span [ngClass]="{ 'font-weight-bold': dia === 3 }">Miércoles - 13:45 - 15:30 y 20:45 - 23:00</span>
                            <br>
                            <span [ngClass]="{ 'font-weight-bold': dia === 4 }">Jueves - 13:45 - 15:30 y 20:45 - 23:00</span>
                            <br>
                            <span [ngClass]="{ 'font-weight-bold': dia === 5 }">Viernes - 13:45 - 16:00 y 20:45 - 23:00</span>
                            <br>
                            <span [ngClass]="{ 'font-weight-bold': dia === 6 }">Sábado - 13:45 - 16:00 y 20:45 - 23:00</span>
                            <br>
                            <span [ngClass]="{ 'font-weight-bold': dia === 0 }">Domingo - 13:45 - 15:30</span>
                            <br>
                            <br>
                            <small>C/ Hermanos Moroy 1, bajo 9. Logroño (La Rioja)</small>
                            <br>
                            <small class="text-center">
                                <i class="fa fa-phone" aria-hidden="true"></i> 941 54 51 41
                            </small>
                            <small class="text-center">
                                <i class="fa fa-phone" aria-hidden="true"></i> 602 26 61 92
                            </small>
                            <br>
                    </div>
                </ng-template>
              </agm-snazzy-info-window>
            </agm-marker>
          </agm-map>
        </div>
    </div>
</div>
