import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-admin-carta',
  templateUrl: './carta.component.html',
  styleUrls: ['./carta.component.scss']
})
export class AdminCartaComponent implements OnInit {

    menus: any[];
    selection: any;

    selectedMenu: any;

    loaded = false;

    constructor(private apiService: ApiService, private toast: ToastService) {
        this.apiService.GetMenus(true).then((menus) => {
            this.menus = menus;
            this.loaded = true;
        });
    }

    ngOnInit() {

    }

    cloneSection(section) {
        this.selectedMenu.sections.push(section);
    }

    createMenu() {
        var newMenu = {
            title: "Nueva carta",
            sections: []
        };

        this.menus.push(newMenu);

        this.selectedMenu = newMenu;
    }

    moveUpSection(section) {
        var idx = this.selectedMenu.sections.findIndex(s => s == section);

        this.selectedMenu.sections.splice(idx, 1);
        this.selectedMenu.sections.splice(idx - 1, 0, section);
    }

    moveDownSection(section) {
        var idx = this.selectedMenu.sections.findIndex(s => s == section);

        this.selectedMenu.sections.splice(idx, 1);
        this.selectedMenu.sections.splice(idx + 1, 0, section);
    }

    moveUpMenu() {
        var idx = this.menus.findIndex(s => s.id == this.selectedMenu.id || s == this.selectedMenu);

        this.menus.splice(idx, 1);
        this.menus.splice(idx - 1, 0, this.selectedMenu);

        console.log(idx);
        console.log(this.menus);
    }

    moveDownMenu() {
        var idx = this.menus.findIndex(s => s.id == this.selectedMenu.id || s == this.selectedMenu);

        this.menus.splice(idx, 1);
        this.menus.splice(idx + 1, 0, this.selectedMenu);

        console.log(idx);
        console.log(this.menus);
    }

    createSection() {
        var newSection = {
            title: "Nueva sección",
            items: []
        };

        this.selectedMenu.sections.push(newSection);
        console.log(this.selectedMenu);
    }

    deleteSection(section) {
        var idx = this.selectedMenu.sections.findIndex(s => s == section);
        this.selectedMenu.sections.splice(idx, 1);
    }

    async postMenu() {
        try {
            var selectedIdx = this.menus.findIndex(x => x == this.selectedMenu);
            var res = await this.apiService.PostMenu(this.menus);
            setTimeout(
                () => this.toast.success("Cartas actualizadas correctamente")
            );
            this.menus = res;
            this.selectedMenu = this.menus[selectedIdx];
        } catch (err) {
            console.log(err);
            setTimeout(
                () => this.toast.error("No se han podido actualizar las cartas. Error: " + err)
            );
        }
    }

    async getSelectedMenu(item) {
        //console.log(item);
        //if (item.id) {
        //    try {
        //        var menu = await this.apiService.GetMenu(item.id);
        //        this.selectedMenu = menu;
        //        console.log(menu);
        //    } catch (err) {
        //        console.log(err);
        //    }
        //} else {
        //    this.selectedMenu = item;
        //}
        this.selectedMenu = item;
    }

}
