import { Component, OnInit } from '@angular/core';
import { IMyOptions } from 'ng-uikit-pro-standard';
import * as moment from 'moment';
import { AppReserva } from '../../classes/appreserva';
import { ApiService } from '../../api.service';
@Component({
  selector: 'app-crear-reserva',
  templateUrl: './crear-reserva.component.html',
  styleUrls: ['./crear-reserva.component.scss']
})
export class CrearReservaComponent implements OnInit {

    public isMeeting(date: Date) {
        return /10|15|20/.test(date.getDate().toString()) ? 'meeting' : '';
    }

    public isYearMeeting(date: Date) {
        return date.getMonth() % 3 ? 'meeting' : '';
    }

    public isDecadeMeeting(date: Date) {
        return date.getDate() % 2 ? 'meeting' : '';
    }

    public isCenturyMeeting(date: Date) {
        return date.getDate() % 20 ? 'meeting' : '';
    }

    fechaReserva: Date = new Date();

    horarios: IHorario[] = [{ Hour: 13, Minute: 0 }, { Hour: 13, Minute: 30 }, { Hour: 14, Minute: 0 }, { Hour: 14, Minute: 30 }, { Hour: 15, Minute: 0 }, { Hour: 21, Minute: 0 }, { Hour: 21, Minute: 30 }, { Hour: 22, Minute: 0 }, { Hour: 22, Minute: 30 }, { Hour: 23, Minute: 0 }];

    horarioSeleccionado: IHorario;

    reserva: AppReserva = new AppReserva();

    constructor(private apiService: ApiService) {
        this.setFecha(this.fechaReserva);
    }

    ngOnInit() {
    }

    setFecha(value: Date) {
        this.reserva.year = value.getFullYear();
        this.reserva.month = value.getMonth() + 1;
        this.reserva.day = value.getDate();
    }

    setHorario(horario: IHorario) {
        this.horarioSeleccionado = horario;
        this.reserva.hour = horario.Hour;
        this.reserva.minute = horario.Minute;
    }

    solicitarReserva() {
        console.log(this.reserva);
        this.apiService.PostReserva(this.reserva);
    }

}

export interface IHorario {
    Hour: number;
    Minute: number;
}
