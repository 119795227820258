import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { JSON } from 'ta-json';
import { AppReserva, ReservaStatus } from './classes/appreserva';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    private apiUrl: string = this.configService.apiUrl;
    private reservasUrl: string = this.configService.apiUrl + 'reservas';
    private instagramUrl: string = this.configService.apiUrl + 'instagram';

    constructor(private http: HttpClient, private configService: ConfigService) {

    }

    GetReserva(id: string, code: string) {

        let params = new HttpParams();
        params = params.append('id', id);
        params = params.append('code', code);

        return this.http.get<AppReserva>(this.reservasUrl + '/Get', { params: params }).toPromise();//.map(x => JSON.parse<AppPlantilla>(x, AppPlantilla)).toPromise();
    }

    ConfirmarEmailReserva(id: string, code: string) {

        let params = new HttpParams();
        params = params.append('id', id);
        params = params.append('code', code);

        console.log("Confirmando...");

        return this.http.get<AppReserva>(this.reservasUrl + '/ConfirmarReserva', { params: params }).toPromise();//.map(x => JSON.parse<AppPlantilla>(x, AppPlantilla)).toPromise();
    }

    GetReservas(ini: Date, fin: Date, soloConfirmadas?: boolean) {

        let params = new HttpParams();
        params = params.append('ini', ini.toISOString());
        params = params.append('fin', fin.toISOString());
        if (soloConfirmadas) {
            params = params.append('soloConfirmadas', soloConfirmadas ? '1' : '0');
        }

        return this.http.get<AppReserva[]>(this.reservasUrl + '/GetReservas', { params: params }).toPromise();//.map(x => JSON.parse<AppPlantilla>(x, AppPlantilla)).toPromise();
    }

    PostReserva(reserva: AppReserva) {
        let params = {
            Reserva: reserva
        }

        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<any>(this.reservasUrl + '/Solicitar', params, { headers: headers }).toPromise();
    }
     
    PostComentarioReserva(reservaId: string, mensaje: string) {
        let params = {
            IdReserva: reservaId,
            Message: mensaje
        }

        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<any>(this.reservasUrl + '/PostComentarioReserva', params, { headers: headers }).toPromise();
    }

    PostEstadoReserva(reservaId: string, estado: ReservaStatus) {
        let params = {
            IdReserva: reservaId,
            Status: estado
        }

        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<any>(this.reservasUrl + '/PostEstadoReserva', params, { headers: headers }).toPromise();
    }

    InstagramGetLatestUserMedia() {
        return this.http.get<any>(this.instagramUrl + '/GetLatestUserMedia', {}).toPromise();
    }

    GetMenus(full: boolean) {
        let params = new HttpParams();
        params = params.append('full', full.toString());

        return this.http.get<any[]>(this.apiUrl + 'Menu/GetMenus', { params: params }).toPromise();//.map(x => JSON.parse<AppPlantilla>(x, AppPlantilla)).toPromise();
    }

    GetMenu(id: string) {
        let params = new HttpParams();
        params = params.append('id', id);

        return this.http.get<any>(this.apiUrl + 'Menu', { params: params }).toPromise();//.map(x => JSON.parse<AppPlantilla>(x, AppPlantilla)).toPromise();
    }

    PostMenu(menu: any[]) {

        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post<any>(this.apiUrl + 'Menu', menu, { headers: headers }).toPromise();
    }

  //FetchPlantillas() {
  //  let params = new HttpParams();

  //  return this.http.get(this.plantillaConfigUrl + '/FetchList', { params: params, responseType: 'text' }).map(x => JSON.parse<ScanNodePlantillas[]>(x, ScanNodePlantillas)).toPromise();
  //}

  //GetPlantilla(plano: string) {

  //  let params = new HttpParams();
  //  params = params.append('plano', plano);

  //  return this.http.get(this.plantillaConfigUrl, { params: params, responseType: "text" }).map(x => JSON.parse<AppPlantilla>(x, AppPlantilla)).toPromise();
  //}

  //CambiarPosicionPrivada(pos: AppPosicion) {

  //  let params = new HttpParams();
  //  params = params.append('id', pos.Id.toString());

  //  return this.http.get<AppPosicion>(this.posicionConfigUrl + '/ChangePrivateCopy', { params: params }).toPromise();
  //}

  //SetPosicionCentro(posId: number, centro: string) {

  //  let params = new HttpParams();
  //  params = params.append('id', posId.toString());
  //  params = params.append('centro', centro);

  //  return this.http.get<AppPosicion>(this.posicionConfigUrl + '/SetPositionCenter', { params: params }).toPromise();
  //}

  //PostPlantilla(plantilla: AppPlantilla) {

  //  let params = {
  //    Plantilla: plantilla
  //  }

  //  let headers = new HttpHeaders().set('Content-Type', 'application/json');

  //  return this.http.post<any>(this.plantillaConfigUrl, params, { headers: headers }).toPromise();
  //}

  //ImportPlantilla(plantilla: AppPlantilla) {

  //  let params = {
  //    Plantilla: plantilla
  //  }

  //  let headers = new HttpHeaders().set('Content-Type', 'application/json');

  //  return this.http.post(this.plantillaConfigUrl + '/Import', params, { headers: headers }).toPromise();
  //}


  //GetCentros() {

  //  let params = new HttpParams();

  //  return this.http.get<string[]>(this.posicionConfigUrl + "/GetCentros", { params: params }).toPromise();
  //}

  //GetPosicion(id: number) {

  //  let params = new HttpParams();
  //  params = params.append('id', id.toString());

  //  return this.http.get(this.posicionConfigUrl, { params: params, responseType: 'text' }).map(x => JSON.parse<AppPosicion>(x, AppPosicion)).toPromise();
  //}

  //DeletePosicion(id: number) {

  //  let params = new HttpParams();
  //  params = params.append('id', id.toString());

  //  return this.http.get<AppPosicion>(this.posicionConfigUrl + "/Delete", { params: params }).toPromise();
  //}

  //GetNext(id: number) {

  //  let params = new HttpParams();
  //  params = params.append('id', id.toString());

  //  return this.http.get(this.posicionConfigUrl + '/Next', { params: params, responseType: 'text' }).map(x => JSON.parse<AppPosicion>(x, AppPosicion)).toPromise();
  //}

  //GetPrev(id: number) {

  //  let params = new HttpParams();
  //  params = params.append('id', id.toString());

  //  return this.http.get(this.posicionConfigUrl + '/Prev', { params: params, responseType: 'text' }).map(x => JSON.parse<AppPosicion>(x, AppPosicion)).toPromise();
  //}

  //GetLast(plano: string) {

  //  let params = new HttpParams();
  //  params = params.append('plano', plano);

  //  return this.http.get(this.posicionConfigUrl + '/Last', { params: params, responseType: 'text' }).map(x => JSON.parse<AppPosicion>(x, AppPosicion)).toPromise();
  //}

  //GetPosiciones(desde: Moment, hasta: Moment, planos: string[] = [], centros: string[] = []) {

  //  let params = new HttpParams();

  //  params = params.append('desde', desde.toISOString());
  //  params = params.append('hasta', hasta.toISOString());
  //  planos.forEach(plano => {
  //    params = params.append('planos', plano);
  //  });
  //  centros.forEach(centro => {
  //    params = params.append('centros', centro);
  //  });

  //  return this.http.get<AppPosicion[]>(this.posicionConfigUrl + '/GetPosiciones', { params: params }).toPromise();
  //}

  //GetPages(desde: Moment, hasta: Moment, itemsPerPage: number = 24, planos: string[] = [], centros: string[] = []) {

  //  let params = new HttpParams();

  //  params = params.append('desde', desde.toISOString());
  //  params = params.append('hasta', hasta.toISOString());
  //  params = params.append('itemsPerPage', itemsPerPage.toString());
  //  planos.forEach(plano => {
  //    params = params.append('planos', plano);
  //  });
  //  centros.forEach(centro => {
  //    params = params.append('centros', centro);
  //  });

  //  return this.http.get<number>(this.posicionConfigUrl + '/GetPages', { params: params }).toPromise();
  //}

  //GetPosicionesPage(desde: Moment, hasta: Moment, page: number = 1, itemsPerPage: number = 24, planos: string[] = [], centros: string[] = []) {

  //  let params = new HttpParams();

  //  params = params.append('desde', desde.toISOString());
  //  params = params.append('hasta', hasta.toISOString());
  //  params = params.append('page', page.toString());
  //  params = params.append('itemsPerPage', itemsPerPage.toString());
  //  planos.forEach(plano => {
  //    params = params.append('planos', plano);
  //  });
  //  centros.forEach(centro => {
  //    params = params.append('centros', centro);
  //  });

  //  return this.http.get(this.posicionConfigUrl + '/GetPosicionesPage', { params: params, responseType: 'text' }).map(x => JSON.parse<AppPosicion[]>(x, AppPosicion)).toPromise();
  //}

  //GetPlanos(desde: Moment, hasta: Moment) {

  //  let params = new HttpParams();
  //  params = params.append('desde', desde.toISOString());
  //  params = params.append('hasta', hasta.toISOString());

  //  return this.http.get<string[]>(this.posicionConfigUrl + '/GetPlanos', { params: params }).toPromise();
  //}

  //PostPosicion(posicion: AppPosicion) {

  //  let params = {
  //    Posicion: posicion
  //  }

  //  let headers = new HttpHeaders().set('Content-Type', 'application/json');

  //  return this.http.post<any>(this.posicionConfigUrl, params, { headers: headers }).toPromise();
  //}

  //PostReferencia(referencia: AppReferencia) {

  //  let params = {
  //    Referencia: referencia
  //  }

  //  let headers = new HttpHeaders().set('Content-Type', 'application/json');

  //  return this.http.post<any>(this.plantillaConfigUrl + '/AddReferencia', params, { headers: headers }).toPromise();
  //}

  //DelReferencia(id: number) {

  //  let params = new HttpParams();
  //  params = params.append('id', id.toString());

  //  let headers = new HttpHeaders().set('Content-Type', 'application/json');

  //  return this.http.get<any>(this.plantillaConfigUrl + '/DelReferencia', { params: params }).toPromise();
  //}
  
}
