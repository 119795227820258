<div class="container py-5 mt-4">
    <div class="text-center">
        <h1>Listado de reservas</h1>
        <kendo-multiviewcalendar kendoDateRangeSelection
                                 [selectionRange]="range" (selectionRangeChange)="onRangeChange($event)"></kendo-multiviewcalendar>
    </div>

    <table mdbTable hover="true">
        <thead>
            <tr>
                <th>Acciones</th>
                <th>Estado</th>
                <th>Hora Reserva</th>
                <th>Nombre</th>
                <th>Comensales</th>
                <th>Teléfono</th>
                <th>Email</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let reserva of reservas">
                <tr mdbTableCol>
                    <td>
                        <button mdbBtn type="button" gradient="blue" size="sm" mdbWavesEffect class="btn-config" (click)="reserva._status = !reserva._status">
                            <mdb-icon fas icon="cog"></mdb-icon>
                        </button>
                        <button mdbBtn type="button" gradient="blue" size="sm" mdbWavesEffect class="btn-config" (click)="reserva._chat = !reserva._chat">
                            <mdb-icon far icon="comments"></mdb-icon> {{reserva.comments.length}}
                        </button>
                    </td>
                    <td>
                        <p *ngIf="reserva.status == estadoReserva.Created">
                            <mdb-icon size="2x" class="yellow-text" fas icon="check"></mdb-icon>
                        </p>
                        <a *ngIf="bitwise(reserva.status, estadoReserva.ConfirmedByGuest)">
                            <mdb-icon size="2x" class="green-text" fas icon="check"></mdb-icon>
                        </a>
                        <p *ngIf="bitwise(reserva.status, estadoReserva.ConfirmedByOwner)">
                            <mdb-icon size="2x" class="green-text" fas icon="check-double"></mdb-icon>
                        </p>
                        <p *ngIf="bitwise(reserva.status, estadoReserva.CancelledByOwner) || bitwise(reserva.status, estadoReserva.CancelledByGuest)">
                            <mdb-icon size="2x" class="red-text" fas icon="calendar-times"></mdb-icon>
                        </p>
                    </td>
                    <td>{{reserva.day | number:'2.0-0'}}/{{reserva.month | number:'2.0-0'}}/{{reserva.year}} {{reserva.hour| number:'2.0-0'}}:{{reserva.minute| number:'2.0-0'}}</td>
                    <td>{{reserva.name}}</td>
                    <td>{{reserva.customers}}</td>
                    <td>{{reserva.phone}}</td>
                    <td>{{reserva.email}}</td>
                </tr>
                <tr mdbTableCol *ngIf="reserva._status">
                    <td colspan="4" class="text-center tdborder">
                        <button mdbBtn type="button" color="success" mdbWavesEffect (click)="postEstadoReserva(reserva.id, estadoReserva.ConfirmedByOwner);">Confirmar</button>
                        <button mdbBtn type="button" color="warning" mdbWavesEffect>Editar</button>
                        <button mdbBtn type="button" color="danger" mdbWavesEffect (click)="postEstadoReserva(reserva.id, estadoReserva.CancelledByOwner);">Cancelar</button>
                    </td>
                    <td colspan="3" class="tdborder">
                        <table mdbTable>
                            <thead>
                                <tr>
                                    <th>Tipo de cambio</th>
                                    <th>Valor</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let log of reserva.logs">
                                    <tr mdbTableCol>
                                        <td>{{traduceCambio(log.cambio)}}</td>
                                        <td>
                                            <p *ngIf="log.estado == estadoReserva.Created">
                                                <mdb-icon size="2x" class="yellow-text" fas icon="check"></mdb-icon>
                                            </p>
                                            <a *ngIf="bitwise(log.estado, estadoReserva.ConfirmedByGuest)">
                                                <mdb-icon size="2x" class="green-text" fas icon="check"></mdb-icon>
                                            </a>
                                            <p *ngIf="bitwise(log.estado, estadoReserva.ConfirmedByOwner)">
                                                <mdb-icon size="2x" class="green-text" fas icon="check-double"></mdb-icon>
                                            </p>
                                            <p *ngIf="bitwise(log.estado, estadoReserva.CancelledByOwner) || bitwise(reserva.status, estadoReserva.CancelledByGuest)">
                                                <mdb-icon size="2x" class="red-text" fas icon="calendar-times"></mdb-icon>
                                            </p>
                                        </td>
                                        <td>{{log.creationDate | amLocal | amDateFormat: 'DD/MM/YYYY HH:mm'}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr mdbTableCol *ngIf="reserva._chat">
                    <td class="chat-message" colspan="7">
                        <ul class="list-unstyled chat">
                            <ng-container *ngFor="let message of reserva.comments">
                                <li *ngIf="!message.isOwner; else ownerBlock" class="d-flex justify-content-between mb-4">
                                    <div class="chat-body white p-3 z-depth-1">
                                        <div class="header">
                                            <strong class="primary-font">{{reserva.name}}</strong>
                                            <small class="pull-right text-muted ml-1"><mdb-icon far icon="clock"></mdb-icon> {{message.creationDate | amLocal | amTimeAgo}}</small>
                                        </div>
                                        <hr class="w-100">
                                        <p class="mb-0 newlines">
                                            {{message.text}}
                                        </p>
                                    </div>
                                    <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg" alt="avatar" class="avatar rounded-circle d-flex align-self-start ml-2 z-depth-1">
                                </li>
                                <ng-template #ownerBlock>
                                    <li class="d-flex justify-content-between mb-4">
                                        <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg" alt="avatar" class="avatar rounded-circle d-flex align-self-start mr-2 z-depth-1">
                                        <div class="chat-body white p-3 ml-2 z-depth-1">
                                            <div class="header">
                                                <strong class="primary-font">Owner</strong>
                                                <small class="pull-right text-muted ml-1"><mdb-icon far icon="clock"></mdb-icon> {{message.creationDate | amLocal | amTimeAgo}}</small>
                                            </div>
                                            <hr class="w-100">
                                            <p class="mb-0 newlines">
                                                {{message.text}}
                                            </p>
                                        </div>
                                    </li>
                                </ng-template>
                                <li class="d-flex justify-content-between mb-4">
                                    <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg" alt="avatar" class="avatar rounded-circle d-flex align-self-start mr-2 z-depth-1">
                                    <div class="chat-body white p-3 ml-2 z-depth-1">
                                        <div class="header">
                                            <strong class="primary-font">Owner</strong>
                                            <small class="pull-right text-muted ml-1"><mdb-icon far icon="clock"></mdb-icon> {{message.creationDate | amLocal | amTimeAgo}}</small>
                                        </div>
                                        <hr class="w-100">
                                        <p class="mb-0">
                                            ¡Entendido!
                                        </p>
                                    </div>
                                </li>
                            </ng-container>

                            <li class="white">
                                <div class="form-group basic-textarea">
                                    <textarea class="form-control pl-2 my-0" id="exampleFormControlTextarea2" rows="3" placeholder="Escriba una respuesta..." [(ngModel)]="reserva._newMessage"></textarea>
                                </div>
                            </li>
                        </ul>
                        <button type="button" mdbBtn color="info" rounded="true" size="sm" class="waves-effect waves-light float-right" (click)="postComentarioReserva(reserva.id, reserva._newMessage)">Enviar</button>
                    </td>
                </tr>

            </ng-container>
        </tbody>
    </table>

</div>