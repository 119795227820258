<div class="pl-2 pr-2">

    <div class="md-form">

        <div class="input-group">
            <input mdbInput type="text" [(ngModel)]="section.title" name="text" class="form-control">
            <label class="">Título de la sección</label>
            <div class="input-group-append">
                <!--<button class="btn btn-outline-secondary" type="button" (click)="delete()">Borrar</button>-->
                <div class="dropdown" mdbDropdown>
                    <button mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light" type="button"
                            mdbWavesEffect>
                        Acciones
                    </button>

                    <div class="dropdown-menu dropdown-primary">
                        <a class="dropdown-item" (click)="createItem()" href="javascript:void(0)">
                            <mdb-icon far icon="plus-square"></mdb-icon>
                            Añadir item
                        </a>
                        <a class="dropdown-item" (click)="delete()" href="javascript:void(0)">
                            <mdb-icon far icon="trash-alt"></mdb-icon>
                            Borrar
                        </a>
                        <a class="dropdown-item" (click)="clone()" href="javascript:void(0)">
                            <mdb-icon far icon="copy"></mdb-icon>
                            Clonar
                        </a>
                        <a class="dropdown-item" (click)="moveUp()" href="javascript:void(0)">
                            <mdb-icon fas icon="arrow-up"></mdb-icon>
                            Subir
                        </a>
                        <a class="dropdown-item" (click)="moveDown()" href="javascript:void(0)">
                            <mdb-icon fas icon="arrow-down"></mdb-icon>
                            Bajar
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <app-admin-carta-item *ngFor="let item of section.items" [item]="item" (onDelete)="deleteItem($event)" (onClone)="cloneItem($event)" (onMoveUp)="moveUpItem($event)" (onMoveDown)="moveDownItem($event)"></app-admin-carta-item>

    <!--<button mdbBtn (click)="createItem()" type="button" color="primary" outline="true" mdbWavesEffect>Añadir plato</button>-->

</div>