import { Injectable, isDevMode } from '@angular/core';
import { IMyOptions } from 'ng-uikit-pro-standard';

import * as moment from 'moment';
import { PackageJson } from './package';
//import { TranslateService } from '@ngx-translate/core';

const { version: appVersion } = require('../../package.json');

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  w: any = window;
  
  public host = this.w.location.protocol + '//' + this.w.location.hostname;
  public port = this.w.location.port;

  public idUrl: string = this.packageJson.Config["identity_host"] + '/api/';
  public apiUrl: string = this.host + (this.port ? (':' + this.port) : '') + '/api/';

  public turnos: Array<Turno> = [
    new Turno(1, 'MAN', 6, 14),
    new Turno(2, 'TAR', 14, 22),
    new Turno(3, 'NOC', 22, 6)
  ];

  public datePickerOptions: IMyOptions = {

    // Strings and translations
    dayLabels: { su: 'D', mo: 'L', tu: 'M', we: 'X', th: 'J', fr: 'V', sa: 'S' },
    dayLabelsFull: { su: "Domingo", mo: "Lunes", tu: "Martes", we: "Miércoles", th: "Jueves", fr: "Viernes", sa: "Sábado" },
    monthLabels: { 1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
    monthLabelsFull: { 1: "Enero", 2: "Febrero", 3: "Marzo", 4: "Abril", 5: "Mayo", 6: "Junio", 7: "Julio", 8: "Agosto", 9: "Septiembre", 10: "Octubre", 11: "Noviembre", 12: "Diciembre" },

    // Buttons
    todayBtnTxt: "Hoy",
    clearBtnTxt: "Borrar",
    closeBtnTxt: "Cerrar",

    // Format
    dateFormat: 'dd/mm/yyyy',

    // First day of the week
    firstDayOfWeek: 'mo',

    // Disable dates
    /*
    disableUntil: undefined,
    disableSince: undefined,
    disableDays: undefined,
    disableDateRanges: undefined,
    disableWeekends: false,
    

    // Enable dates (when disabled)
    enableDays: undefined,
    */

    // Year limits
    minYear: 1000,
    maxYear: 9999,

    // Show Today button
    showTodayBtn: true,

    //Show Clear date button
    showClearDateBtn: true,

    markCurrentDay: true,
    //markDates: undefined,
    //markWeekends: undefined,
    disableHeaderButtons: false,
    showWeekNumbers: true,
    height: '20px',
    width: '100%',
    selectionTxtFontSize: '15px'
  };

  constructor(private packageJson: PackageJson/* ,private translate: TranslateService*/) {

  }

  public setLocale() {

    let newDatePickerOptions: IMyOptions = {
      // Strings and translations
      dayLabels: { su: 'DAYLABELS.SHORT.SU', mo: 'DAYLABELS.SHORT.MO', tu: 'DAYLABELS.SHORT.TU', we: 'DAYLABELS.SHORT.WE', th: 'DAYLABELS.SHORT.TH', fr: 'DAYLABELS.SHORT.FR', sa: 'DAYLABELS.SHORT.SA' },
      dayLabelsFull: { su: 'DAYLABELS.FULL.SU', mo: "DAYLABELS.FULL.MO", tu: "DAYLABELS.FULL.TU", we: "DAYLABELS.FULL.WE", th: "DAYLABELS.FULL.TH", fr: "DAYLABELS.FULL.FR", sa: "DAYLABELS.FULL.SA" },
      monthLabels: { 1: 'MONTHLABELS.SHORT.1', 2: 'MONTHLABELS.SHORT.2', 3: 'MONTHLABELS.SHORT.3', 4: 'MONTHLABELS.SHORT.4', 5: 'MONTHLABELS.SHORT.5', 6: 'MONTHLABELS.SHORT.6', 7: 'MONTHLABELS.SHORT.7', 8: 'MONTHLABELS.SHORT.8', 9: 'MONTHLABELS.SHORT.9', 10: 'MONTHLABELS.SHORT.10', 11: 'MONTHLABELS.SHORT.11', 12: 'MONTHLABELS.SHORT.12' },
      monthLabelsFull: { 1: "MONTHLABELS.FULL.1", 2: "MONTHLABELS.FULL.2", 3: "MONTHLABELS.FULL.3", 4: "MONTHLABELS.FULL.4", 5: "MONTHLABELS.FULL.5", 6: "MONTHLABELS.FULL.6", 7: "MONTHLABELS.FULL.7", 8: "MONTHLABELS.FULL.8", 9: "MONTHLABELS.FULL.9", 10: "MONTHLABELS.FULL.10", 11: "MONTHLABELS.FULL.11", 12: "MONTHLABELS.FULL.12" },

      // Buttons
      todayBtnTxt: "TODAY",
      clearBtnTxt: "CLEAR",
      closeBtnTxt: "CLOSE",

      // Format
      dateFormat: 'dd/mm/yyyy',

      // First day of the week
      firstDayOfWeek: 'mo',

      // Year limits
      minYear: 1000,
      maxYear: 9999,

      // Show Today button
      showTodayBtn: true,

      //Show Clear date button
      showClearDateBtn: true,

      markCurrentDay: true,
      disableHeaderButtons: false,
      showWeekNumbers: true,
      height: '20px',
      width: '100%',
      selectionTxtFontSize: '15px'
    };

    //this.translate.get("GLOBAL").subscribe((res: string) => {

    //  newDatePickerOptions.dayLabels.su = this.deepvalue(res, newDatePickerOptions.dayLabels.su);
    //  newDatePickerOptions.dayLabels.mo = this.deepvalue(res, newDatePickerOptions.dayLabels.mo);
    //  newDatePickerOptions.dayLabels.tu = this.deepvalue(res, newDatePickerOptions.dayLabels.tu);
    //  newDatePickerOptions.dayLabels.we = this.deepvalue(res, newDatePickerOptions.dayLabels.we);
    //  newDatePickerOptions.dayLabels.th = this.deepvalue(res, newDatePickerOptions.dayLabels.th);
    //  newDatePickerOptions.dayLabels.fr = this.deepvalue(res, newDatePickerOptions.dayLabels.fr);
    //  newDatePickerOptions.dayLabels.sa = this.deepvalue(res, newDatePickerOptions.dayLabels.sa);

    //  newDatePickerOptions.dayLabelsFull.su = this.deepvalue(res, newDatePickerOptions.dayLabelsFull.su);
    //  newDatePickerOptions.dayLabelsFull.mo = this.deepvalue(res, newDatePickerOptions.dayLabelsFull.mo);
    //  newDatePickerOptions.dayLabelsFull.tu = this.deepvalue(res, newDatePickerOptions.dayLabelsFull.tu);
    //  newDatePickerOptions.dayLabelsFull.we = this.deepvalue(res, newDatePickerOptions.dayLabelsFull.we);
    //  newDatePickerOptions.dayLabelsFull.th = this.deepvalue(res, newDatePickerOptions.dayLabelsFull.th);
    //  newDatePickerOptions.dayLabelsFull.fr = this.deepvalue(res, newDatePickerOptions.dayLabelsFull.fr);
    //  newDatePickerOptions.dayLabelsFull.sa = this.deepvalue(res, newDatePickerOptions.dayLabelsFull.sa);

    //  newDatePickerOptions.monthLabels[1] = this.deepvalue(res, newDatePickerOptions.monthLabels[1]);
    //  newDatePickerOptions.monthLabels[2] = this.deepvalue(res, newDatePickerOptions.monthLabels[2]);
    //  newDatePickerOptions.monthLabels[3] = this.deepvalue(res, newDatePickerOptions.monthLabels[3]);
    //  newDatePickerOptions.monthLabels[4] = this.deepvalue(res, newDatePickerOptions.monthLabels[4]);
    //  newDatePickerOptions.monthLabels[5] = this.deepvalue(res, newDatePickerOptions.monthLabels[5]);
    //  newDatePickerOptions.monthLabels[6] = this.deepvalue(res, newDatePickerOptions.monthLabels[6]);
    //  newDatePickerOptions.monthLabels[7] = this.deepvalue(res, newDatePickerOptions.monthLabels[7]);
    //  newDatePickerOptions.monthLabels[8] = this.deepvalue(res, newDatePickerOptions.monthLabels[8]);
    //  newDatePickerOptions.monthLabels[9] = this.deepvalue(res, newDatePickerOptions.monthLabels[9]);
    //  newDatePickerOptions.monthLabels[10] = this.deepvalue(res, newDatePickerOptions.monthLabels[10]);
    //  newDatePickerOptions.monthLabels[11] = this.deepvalue(res, newDatePickerOptions.monthLabels[11]);
    //  newDatePickerOptions.monthLabels[12] = this.deepvalue(res, newDatePickerOptions.monthLabels[12]);

    //  newDatePickerOptions.monthLabelsFull[1] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[1]);
    //  newDatePickerOptions.monthLabelsFull[2] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[2]);
    //  newDatePickerOptions.monthLabelsFull[3] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[3]);
    //  newDatePickerOptions.monthLabelsFull[4] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[4]);
    //  newDatePickerOptions.monthLabelsFull[5] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[5]);
    //  newDatePickerOptions.monthLabelsFull[6] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[6]);
    //  newDatePickerOptions.monthLabelsFull[7] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[7]);
    //  newDatePickerOptions.monthLabelsFull[8] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[8]);
    //  newDatePickerOptions.monthLabelsFull[9] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[9]);
    //  newDatePickerOptions.monthLabelsFull[10] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[10]);
    //  newDatePickerOptions.monthLabelsFull[11] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[11]);
    //  newDatePickerOptions.monthLabelsFull[12] = this.deepvalue(res, newDatePickerOptions.monthLabelsFull[12]);

    //  newDatePickerOptions.clearBtnTxt = res[newDatePickerOptions.clearBtnTxt];
    //  newDatePickerOptions.closeBtnTxt = res[newDatePickerOptions.closeBtnTxt];
    //  newDatePickerOptions.todayBtnTxt = res[newDatePickerOptions.todayBtnTxt];

    //  this.datePickerOptions = newDatePickerOptions;
    //});

  }

  private deepvalue(obj, path) {
    for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
      obj = obj[path[i]];
    };
    return obj;
  }

  public resuelveTurno(mom: moment.Moment): Turno {

    var encontrado: Turno = null;
    var momento = moment(mom);
    var horaActual = momento.hour();

    var turnoEncontrado = this.turnos.some(function (turno) {
      // Si la hora de inicio es inferior a la de fin
      if (turno.hini < turno.hfin) {
        if (turno.hini <= horaActual && turno.hfin > horaActual) {
          encontrado = turno;
          return true;
        }
        // La hora de fin es del dia siguiente e inferior
      } else {
        if (turno.hini <= horaActual) {
          encontrado = turno;
          return true;
        } else if (turno.hfin >= horaActual) {
          encontrado = turno;
          return true;
        }
      }
    });
    return encontrado;
  }

  public getTurnoByValue(value: number) {
    return this.turnos.find(x => x.value === value);
  }
}

export class Turno {
  constructor(public value: number, public label: string, public hini: number, public hfin: number) {

  }
}
