<!--Footer-->
<footer class="page-footer font-small main-light pt-0">

    <!--Footer Links-->
    <div class="container">

        <!--First row-->
        <div class="row">

            <!--First column-->
            <div class="col-md-12 py-5">

                <div class="mb-6 flex-center">

                    <!--Facebook-->
                    <a class="fb-ic" href="https://www.facebook.com/rajonegro/" target="_blank">
                        <mdb-icon fab icon="facebook-f text-light m-3" size="2x"></mdb-icon>
                    </a>

                    <!--Twitter-->
                    <!-- <a class="tw-ic">
                        <i class="fa fa-twitter fa-lg white-text mr-md-5 mr-3 fa-2x"> </i> 
                    </a> -->
                    <!--Instagram-->
                    <a class="ins-ic" href="https://www.instagram.com/restauranteajonegro/" target="_blank">
                        <mdb-icon fab icon="instagram text-light m-3" size="2x"></mdb-icon>
                    </a>

                </div>

                

            </div>
            <!--/First column-->
        </div>
        <!--/First row-->
    </div>
    <!--/Footer Links-->

    <div class="footer-copyright py-2 text-center">
        <span>
            <i class="fa fa-phone" aria-hidden="true"></i> 941 54 51 41
        </span>
        <span>
            <i class="fa fa-phone" aria-hidden="true"></i> 602 26 61 92
        </span>
        <span>
            <i class="far fa-envelope" aria-hidden="true"></i>&nbsp;<a href="mailto: reservas@restauranteajonegro.com">reservas@restauranteajonegro.com</a>
        </span>
    </div>

    <!--Copyright-->
    <div class="footer-copyright py-1 text-center">
        © <a href="https://www.restauranteajonegro.com"> restauranteajonegro.com </a>
    </div>
    <!--/Copyright-->

</footer>
<!--/Footer-->