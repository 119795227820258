<div class="pl-2 pr-2">

    <div class="md-form">
        <div class="input-group">
            <input mdbInput type="text" [(ngModel)]="item.title" name="text" class="form-control">
            <label class="">Título del item</label>
            <div class="input-group-append">
                <div class="dropdown" mdbDropdown>
                    <button mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light" type="button"
                            mdbWavesEffect>
                        Acciones
                    </button>

                    <div class="dropdown-menu dropdown-primary">
                        <a class="dropdown-item" (click)="createVariant()" href="javascript:void(0)">
                            <mdb-icon far icon="plus-square"></mdb-icon>
                            Añadir variante
                        </a>
                        <a class="dropdown-item" (click)="delete()" href="javascript:void(0)">
                            <mdb-icon far icon="trash-alt"></mdb-icon>
                            Borrar
                        </a>
                        <a class="dropdown-item" (click)="clone()" href="javascript:void(0)">
                            <mdb-icon far icon="copy"></mdb-icon>
                            Clonar
                        </a>
                        <a class="dropdown-item" (click)="moveUp()" href="javascript:void(0)">
                            <mdb-icon fas icon="arrow-up"></mdb-icon>
                            Subir
                        </a>
                        <a class="dropdown-item" (click)="moveDown()" href="javascript:void(0)">
                            <mdb-icon fas icon="arrow-down"></mdb-icon>
                            Bajar
                        </a>
                    </div>
                </div>

            </div>
        </div>
        <div class="input-group">
            <input mdbInput type="text" [(ngModel)]="item.description" name="text" class="form-control">
            <label class="">Descripción del item</label>
        </div>
        <mdb-select-2 placeholder="Tipo" [(ngModel)]="item.type">
            <mdb-select-option *ngFor="let itemType of itemTypes" [value]="itemType.id">{{ itemType.title }}</mdb-select-option>
        </mdb-select-2>
        <div *ngIf="item.type == 1; else bebida" class="row">
            <div class="col-12">
                <kendo-floatinglabel text="Precio">
                    <kendo-numerictextbox [(ngModel)]="item.price" type="number"></kendo-numerictextbox>
                </kendo-floatinglabel>
            </div>
        </div>
        <ng-template #bebida>
            <div class="row">
                <div class="col-6">
                    <kendo-floatinglabel text="Precio copa">
                        <kendo-numerictextbox [(ngModel)]="item.priceCup" type="number"></kendo-numerictextbox>
                    </kendo-floatinglabel>
                </div>
                <div class="col-6">
                    <kendo-floatinglabel text="Precio botella">
                        <kendo-numerictextbox [(ngModel)]="item.priceBottle" type="number"></kendo-numerictextbox>
                    </kendo-floatinglabel>
                </div>
            </div>
        </ng-template>
    </div>

    <app-admin-carta-item-variant *ngFor="let variant of item.variants" [variant]="variant" (onDelete)="deleteVariant($event)" (onClone)="cloneVariant($event)" (onMoveUp)="moveUpVariant($event)" (onMoveDown)="moveDownVariant($event)"></app-admin-carta-item-variant>

    <!--<button mdbBtn (click)="createVariant()" type="button" color="primary" outline="true" mdbWavesEffect>Añadir variante</button>-->

</div>