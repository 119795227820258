import { Injectable, isDevMode, Inject } from "@angular/core";
//import { CustomStorage } from "./storage";
const packagejson = require('../../package.json');

import { InjectionToken } from '@angular/core';

export const PACKAGE_JSON_CONFIG = new InjectionToken<PackageJsonConfig>('package.json.config');

export interface PackageJsonConfig {
    packageJsonConfigRoot: string;
} 

@Injectable({
  providedIn: 'root'
})
export class PackageJson {
  public Config: any;

  /*private storage: CustomStorage*/
    constructor(@Inject(PACKAGE_JSON_CONFIG) jsonConfig: PackageJsonConfig) {
      if (isDevMode()) {
          this.Config = packagejson[jsonConfig.packageJsonConfigRoot]["debug"];
    } else {
          this.Config = packagejson[jsonConfig.packageJsonConfigRoot]["release"];
    }
  }

  //public GetStoredLang(): string {
  //  return this.storage.read("lang");
  //}

  //public SetStoredLang(lang: string) {
  //  this.storage.write("lang", lang);
  //}
}
