import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})
export class MapaComponent implements OnInit {
  title: string = 'Restaurante Ajo Negro';
  latMap: number = 42.4687989;
  lngMap: number = -2.4459416;
  lat: number = 42.4657989;
  lng: number = -2.4459416;
  zoom: number = 16;
  dia: number = moment().day();

  constructor() {

   }

  ngOnInit() {
  }

}
