<div class="view intro cabecera" style="background-image: url(assets/img/1_1.jpg);background-size:cover;background-repeat:no-repeat;background-position: center center; ">
    <div class="full-bg-img mask rgba-white-light">
        <div class="container mainpadding">
            <div class="align-items-center justify-content-center cabecera">
                <div class="row">
                    <div class="col-12 mb-5">
                        <div class="intro-info-content text-center">
                            <h1 class="display-3 mb-2 animated wow fadeInDown maintext" data-wow-delay="0.1s">AJONEGRO</h1>
                            <h3 class="font-up mb-3 mt-1 font-bold animated wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1.5s">Restaurante</h3>
                            <h5 class="font-up mb-3 mt-1 animated font-bold wow fadeIn" data-wow-delay="0.2s" data-wow-duration="1.5s">C/ Hermanos Moroy 1, bajo 9. Logroño (La Rioja)</h5>
                            <a mdbBtn class="btn amber darken-3 btn-lg wow fadeIn waves-light z-depth-1-half" data-wow-delay="0.2s" [routerLink]="'/carta'">CARTA</a>
                            <a mdbBtn class="btn btn-indigo btn-lg wow fadeIn waves-light z-depth-1-half" data-wow-delay="0.2s" [routerLink]="'/mapa'">LOCALÍZANOS</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <!-- <div class="row">
        <div class="col-md-12 text-center mb-5 mt-5 animated wow fadeIn" data-wow-delay="0.8s">
            <div class="container">
                <h1 class="font-bold light-blue-text my-5">Vacaciones Enero 2024</h1>
                <p align="justify">
                    Permenecermos cerrados por vacaciones del lunes 8 a martes 23, ambos inclusive.<br /><br />
                    Para reservas: a través de la web, no se atenderá el teléfono.<br /><br />
                    Para cualquier otra petición, diríjanse a <a href="mailto: reservas@restauranteajonegro.com">reservas@restauranteajonegro.com</a>
                </p>
            </div>
        </div>
    </div>
    <hr> -->
    <div class="row">
        <div class="col-md-12 text-center my-3 animated wow fadeIn" data-wow-delay="0.1s">
            <div class="container">
                <h1 class="font-bold mt-2 mb-4">Horario</h1>
                <p align="justify">
                    <span class="font-weight-bold">LUNES, MARTES Y DOMINGO NOCHE:</span> Cerrado por descanso semanal.
                </p>
                <p align="justify">
                    <span class="font-weight-bold">HORARIO DE MAÑANA:</span> Desde las 13:45 a las 15:15 horas para reservas de servicio de comidas.
                </p>
                <p align="justify">
                    <span class="font-weight-bold">HORARIO POR LA NOCHE:</span> Desde las 20:45 a las 22:00 horas para reservas de servicio de cenas.
                </p>
                <p align="justify">
                    <span class="font-weight-bold">COCINA EN TERRAZA:</span> Hasta las 15:15 horas al mediodía y 23:00 horas a la noche.
                </p>
                <p align="justify">
                    <span class="font-weight-bold">HORARIO MENÚ DEGUSTACIÓN:</span>  La hora límite para él serán las 15:00 horas al mediodía y las 21:45 horas por la noche.
                </p>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12 text-center my-3 animated wow fadeIn" data-wow-delay="0.1s">
            <h1 class="font-bold mt-2 mb-4">Sobre nosotros</h1>
            <p align="justify"><span class="font-weight-bold">AJONEGRO</span> es la unión de dos culturas. Mariana Sánchez, de Cuernavaca, aporta la mitad de la identidad de la gastronomía que queremos enseñar, la mexicana. Por el otro lado, Gonzalo Baquedano, natal de Logroño, aporta la otra mitad, la española. </p>
            <p align="justify">La base de la cocina en Ajonegro, se basa en el respeto al producto de temporada. Cambios estacionales, donde encontrarás variedad de platos más mexicanos, platos más españoles y platos que unen ambas culturas en el mismo, buscando una identidad gastronómica única y diferente en la zona. Siempre fijándonos en una base tradicional a la que intentamos aportar nuestro toque personal diferenciador. </p>
            <p align="right">Mariana Sánchez y Gonzalo Baquedano</p>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12 text-center my-3 animated wow fadeIn" data-wow-delay="0.1s">
            <div class="container">
                <h1 class="font-bold mt-2 mb-4">Reconocimientos</h1>
                <div class="aknowledgements">
                    <figure class="mx-auto">
                        <img src="assets/img/michelin2024.png" class="michelin" />
                        <figcaption class="mt-3">
                            <em>
                                Una estrella en la edición 2024<br/>
                                de la Guía MICHELIN España y Portugal
                            </em>
                        </figcaption>
                    </figure>
                    <figure class="mx-auto">
                        <img src="assets/img/repsol2024.png" class="repsol" />
                        <figcaption class="mt-3">
                            <em>
                                Recomendado en la edición 2024<br/>
                                de la Guía REPSOL
                            </em>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    </div>
</div>
