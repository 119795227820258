import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-confirmar-reserva',
  templateUrl: './confirmar-reserva.component.html',
  styleUrls: ['./confirmar-reserva.component.scss']
})
export class ConfirmarReservaComponent implements OnInit, OnDestroy {
    subs: Subscription[] = [];

    constructor(private route: ActivatedRoute, private apiService: ApiService) {
        this.initialize();
    }

    private async initialize() {
        this.subs.push(this.route.queryParamMap.subscribe(async (params: Params) => {
            console.log(params);
            if (params.params.id && params.params.code) {
                this.apiService.ConfirmarEmailReserva(params.params.id, params.params.code).then(function (reserva) {
                    console.log("Confirmada OK");
                }, function (error) {
                    console.log("Confirmada FAIL");
                    console.log(error);
                });
            }
        }));
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.subs.forEach(x => x.unsubscribe());
    }

}
