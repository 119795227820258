import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-carta-item',
  templateUrl: './carta-item.component.html',
  styleUrls: ['./carta-item.component.scss']
})
export class CartaItemComponent implements OnInit {
    @Input()
    carta: any;

  constructor() { }

  ngOnInit(): void {
  }

}
