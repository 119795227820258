<div class="container text-center py-5 mt-3">
  <!--Card-->
  <div class="card my-4">

      <!--Card image-->
      <div class="view overlay">
          <div class="container">
            <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [type]="'carousel-thumbnails'" [animation]="'fade'">
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/1_1.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/2_1.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/3_1.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/4_1.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/5_1.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/6_1.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/7_1.jpg" alt="First slide">
              </mdb-carousel-item>
          </mdb-carousel>
          </div>
      </div>
      <!--/Card image-->

      <!--Card content-->
      <div class="card-body text-center">
          <!--Title-->
          <h4 class="card-title"><strong>Comedor</strong></h4>
          <h5 class="indigo-text"><strong>Fotografías</strong></h5>

          <p class="card-text">
            Algunas intantáneas de nuestra sala comedor
          </p>

      </div>
      <!--/.Card content-->

  </div>
  <!--/.Card-->

  <div class="card my-4">

      <!--Card image-->
      <div class="view overlay">
          <div class="container">
            <mdb-carousel class="carousel slide carousel-fade" [type]="'carousel-thumbnails'" [animation]="'fade'">
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/1_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/2_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/3_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/4_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/5_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/6_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/7_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/8_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/9_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/10_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/11_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/12_2.jpg" alt="First slide">
              </mdb-carousel-item>
              <mdb-carousel-item>
                <img class="d-block fit" src="/assets/img/13_2.jpg" alt="First slide">
              </mdb-carousel-item>
          </mdb-carousel>
          </div>
      </div>
      <!--/Card image-->

      <!--Card content-->
      <div class="card-body text-center">
          <!--Title-->
          <h4 class="card-title"><strong>Algunos de nuetros platos</strong></h4>
          <h5 class="indigo-text"><strong>En las fotografías</strong></h5>

          <p class="card-text">
            Tosta de anchoa, tartar de atún, steak tartar, tinga que pringa, ceviche mexicano, cazuelita mar y montaña, bacalao al pil-pil, patatas bravas, carrot cake, tarta de queso, tiramisu...
          </p>

      </div>
      <!--/.Card content-->
  </div>
  <!--/.Card-->
</div>