import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent implements OnInit {
  
  images = [
    { img: "/assets/img/1.jpg", thumb: "/assets/img/1.jpg", description: "Image 1" },
    { img: "/assets/img/2.jpg", thumb: "/assets/img/2.jpg", description: "Image 2" },
    { img: "/assets/img/3.jpg", thumb: "/assets/img/3.jpg", description: "Image 3" },
    { img: "/assets/img/4.jpg", thumb: "/assets/img/4.jpg", description: "Image 4" },
    { img: "/assets/img/5.jpg", thumb: "/assets/img/5.jpg", description: "Image 5" },
    { img: "/assets/img/6.jpg", thumb: "/assets/img/6.jpg", description: "Image 6" },
    { img: "/assets/img/7.jpg", thumb: "/assets/img/7.jpg", description: "Image 7" },
    { img: "/assets/img/8.jpg", thumb: "/assets/img/8.jpg", description: "Image 8" },
    { img: "/assets/img/9.jpg", thumb: "/assets/img/9.jpg", description: "Image 9" }
  ];

  constructor() { 
  }

  ngOnInit() {
  }

}
