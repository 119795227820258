<header>         
    <app-nav-menu></app-nav-menu>
</header>     

<!--Main Layout-->
<main>
    <router-outlet></router-outlet>
</main>
<!--Main Layout-->

<app-footer></app-footer>
